@use "../../../styles/variables.module" as *;
@use "../../../styles/mixins";

$margin: 1rem;
$title-font-size-desktop: 21px;
$title-font-size-mobile: 16px;

.groupContainer {
  margin-top: 10px;
  padding-inline: $margin;
  > * {
    vertical-align: top;
  }
  --mediaWidth: calc(50% - #{$margin});
  > img, > video, > iframe {
    display: inline-block;
    width: var(--mediaWidth);
    margin: 0 $margin $margin 0;
    border: none;
    border-radius: 10px;
    height: auto !important;
    position: relative !important;

    @media (max-width: $break-point-sm) {
      margin: 0 1.4rem 0 0;
    }

    & ~ div { // content container when media is present
      width: calc(100% - #{var(--mediaWidth)} - #{$margin * 2});
      display: inline-block;
      margin-left: 0;
    }
  }

  > div:only-child { // group when no media is present
    margin: -10px $margin 0 $margin;
    @media (max-width: $break-point-sm) {
      margin: 0;
    }
  }

  & [data-smallwidth="true"] {
    --mediaWidth: 100px;
    height: var(--mediaWidth);
    width: var(--mediaWidth) !important;

    & ~ div { // content container when media is present
      --mediaWidth: 100px;
      width: calc(100% - var(--mediaWidth) - #{$margin * 2}) !important;
      display: inline-block;
      margin-left: 0;
    }
  }

  @media (max-width: $break-point-sm) {
    padding-inline: 0;
  }
  &CenteredImage {
    @media (max-width: $break-point-sm) {
      text-align: center;
      > img, > video, > iframe {
        max-width: 300px;
        max-height: 300px;
        margin-bottom: 20px;

        & ~ div { // content container when media is present
          width: 100%;
        }
      }

      & [data-smallwidth="true"] {
        max-width: 300px;
        max-height: 300px;
        & ~ div { // content container when media is present
          width: 100% !important;
        }
      }
    }
  }
}

.dateMedia {
  $mediaWidth: 100px;

  height: $mediaWidth;
  width: $mediaWidth;

  display: inline-block;
  background: var(--blocks);
  color: var(--blocksTextColor);
  border-radius: 10px;
  text-align: center;
  font-size: $terrace-subheading-font-size;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 30px;
  margin: 0 $margin $margin $margin;
  vertical-align: top;

  span {
    display: block;

    &:first-of-type {
      padding-top: calc(50px - 30px);
    }
  }

  & + div { // content container when media is present
    width: calc(100% - #{$mediaWidth} - #{$margin * 2});
    display: inline-block;
    margin-left: 0;
  }

  @media (max-width: $break-point-sm) {
    font-size: 18px;
  }
}

.contentContainer {
  overflow: hidden;
  text-align: left;
  .organizationTitle {
    color: $gray;
    font-size: 1rem;
    margin: 1rem 1rem 0 0;
    display: block;
  }
}

.item {
  padding: 0 0 .5rem 0;
  position: relative;
  cursor: pointer;
  max-height: 350px;

  a {
    text-decoration: none !important;
  }

  &Hidden {
    opacity: .5;
  }

  &:hover {
    .curationContainer {
      display: flex;
      flex-direction: row;
    }
  }
}

.dateContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: .5rem;
  @media (max-width: $break-point-sm) {
    display: none;
  }
  font-weight: 0;
}

.header {
  .title {
    font-size: $title-font-size-desktop;
    margin-bottom: calc(1rem - 10px);
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    @media(max-width: $break-point-sm) {
      font-size: $title-font-size-mobile;
      line-height: normal;
      p {
        -webkit-line-clamp: 5;
      }
    }
    color: $black;
    font-weight: $terrace-subheading-font-weight-two;
  }

  .title + .description { // only add margin when there's a title
    margin-top: .5rem;
  }

  .description {
    color: $black;
    font-size: 18px;
    line-height: 1.95rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    @media (max-width: $break-point-sm) {
      display: none;
    }
  }

  span[class*="flaticon-"] {
    color: var(--blocks);
  }

  .sourceIcon {
    float: left;
    margin-right: .5rem;
    @include mixins.socialColor(color);
  }
}

.expandButton {
  text-align: center;
  margin-top: -20px;

  button {
    color: var(--blocksTextColor);
    background-color: var(--blocks);
    border-radius: 10px;
    font-size: .9rem;
  }
}

.dateLocationContainer {
  padding-top: .5rem;

  img { // file/folder icon
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.curationContainer {
  right: 0;
  top: 0;
  display: none;
  position: absolute;
  z-index: 5;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $admin-blue-dark;
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    margin-right: 5px;
    padding: 4px 6px;

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.contentItemDate {
  font-weight: 0 !important;
  div {
    display: inline-block;
  }
}

.date {
  color: gray;
  font-size: 1rem;
}

.orgTitle {
  color: $black;
  font-size: 1rem;
}

.meritChatCallout {
  float: left;
  @include mixins.meritChatCallout($title-font-size-desktop, 5px, 5px);
  @media(max-width: $break-point-sm) {
    font-size: $title-font-size-mobile;
  }
}
.titleContainer{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.joinTheDiscussion {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 2px 10px;
  margin-top: 5px;
  border-radius: 10px;
  overflow: hidden;
  color: $black !important;
  background: linear-gradient(to left, transparent 50%, var(--secondaryNavBackground) 50%) right;
  background-size: 200% 100%;
  border: solid 1px $black;
  transition: all .4s ease-in-out;
  outline: solid 1px $black;
  outline-offset: -1.5px;
  &::before {
    content: "Join Discussion";
    @media(max-width: $break-point-sm) {
      content: "Discuss";
    }
  }

  &:hover{
    background-position:left;
    color: var(--secondaryNavBackgroundTextColor) !important;
    border: solid 1px var(--secondaryNavBackground);
    outline: solid 1px var(--secondaryNavBackground);
  }
}


